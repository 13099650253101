const Home = () => {
  return (
    <>
      <div className="hero">
        <p>[hero]</p>
      </div>
      <div>
        <p>
          Pellentesque tristique odio ut augue ultricies placerat. Donec vel risus non quam molestie
          suscipit a quis erat. Morbi vel laoreet ante. Vivamus pharetra quis neque eu feugiat.
          Etiam a enim velit. Proin dolor tortor, elementum id tincidunt a, ultrices viverra purus.
          Integer non elit eget diam porttitor placerat at a lorem.
        </p>
        <p>
          Integer mauris enim, laoreet ut eros eget, fermentum viverra justo. Praesent faucibus eu
          ante sed vestibulum. Mauris congue nibh nec mi congue, vitae posuere tortor varius. Mauris
          dapibus rutrum felis, nec ullamcorper nisi euismod quis. Praesent vestibulum venenatis
          nisl. Morbi finibus dignissim viverra. Maecenas porta non nulla eu condimentum.
        </p>
        <p>
          Nam lobortis sagittis nisl, nec aliquet neque pellentesque et. Mauris non iaculis nunc.
          Aliquam malesuada odio sit amet nunc lacinia, non dictum turpis vulputate. Ut facilisis
          nulla ipsum, vel imperdiet nulla ultrices sit amet. In velit neque, fringilla vel tellus
          vitae, dictum porttitor odio. Donec auctor porttitor ex, vel vulputate tellus sodales a.
          Ut luctus bibendum eros nec scelerisque. Vestibulum vitae semper elit. Aliquam a semper
          ex, a sodales neque. Aenean vehicula gravida tristique. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Pellentesque vitae ante vitae lectus
          sodales sollicitudin. Cras tincidunt iaculis risus. Integer sapien sem, feugiat eget
          scelerisque finibus, ornare ac justo.
        </p>
        <p>
          Phasellus iaculis vitae lacus ac tempus. Fusce quis malesuada erat. Donec vitae turpis
          dictum, finibus felis et, pulvinar justo. Cras a massa enim. Fusce commodo eget orci non
          maximus. Nulla ut sapien feugiat, sollicitudin nisi eu, lacinia magna. Sed ac magna a quam
          lobortis ornare eu et lorem. Curabitur molestie sollicitudin ultrices. Duis eget
          ullamcorper nisl. Praesent fermentum commodo rhoncus.
        </p>
        <p>
          Quisque vestibulum, ligula id vehicula dignissim, ligula nulla pretium nulla, sed dapibus
          velit mi id turpis. Phasellus sodales in neque sit amet luctus. Donec pellentesque
          dignissim feugiat. Praesent vel nisi semper, scelerisque nisi in, ornare velit. Phasellus
          lacinia dui nibh, convallis sagittis nisl lobortis sit amet. Vestibulum mollis augue
          mollis, dictum ante nec, finibus orci. Sed vitae sollicitudin mi. Nam porttitor elit quis
          dolor blandit, quis lacinia nisl venenatis. Donec accumsan elit eget leo eleifend, nec
          consequat dolor ultrices. Curabitur facilisis volutpat posuere. Fusce aliquam nisi nec
          gravida placerat.
        </p>
        <p>
          Aliquam viverra facilisis massa, eget tempus enim eleifend quis. Quisque sed consectetur
          lacus, nec scelerisque metus. Pellentesque odio erat, gravida id augue ac, porta dapibus
          elit. Donec pharetra malesuada nibh, ac luctus quam pulvinar ac. Suspendisse ut sapien sit
          amet sem vulputate cursus quis non tellus. Vestibulum sollicitudin egestas fringilla. Sed
          molestie et diam eget egestas. Vestibulum sed orci sed sapien placerat vulputate. Ut at
          justo non nibh dictum convallis sollicitudin non lorem. Duis in dapibus ligula. Nunc
          tempor risus sit amet odio varius volutpat. Fusce vitae lacus ut ex convallis tincidunt.
          Quisque venenatis porta lacinia.
        </p>
      </div>
    </>
  );
};

export default Home;
