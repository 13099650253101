import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { layoutNames } from '../../routes';

const Layout = ({ children, layout }) => {
  return (
    <div className={`layout ${layout}`}>
      {layout !== layoutNames.auth && <Header layout={layout} />}
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
