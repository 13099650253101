import Axios from 'axios';
import { useProvider } from '../context';

const useAxios = () => {
  const { token } = useProvider();

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: { Authorization: `Bearer ${token}` },
  });

  return axios;
};

export default useAxios;
