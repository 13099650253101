import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <p>[dashboard]</p>
    </div>
  );
};

export default Dashboard;
