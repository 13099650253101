import React from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routes';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <div className="login">
      <p>[login]</p>
      <LoginForm />
      <Link to={routeNames.register}>Register</Link>
      <Link to={routeNames.forgot}>Forgot password</Link>
    </div>
  );
};

export default Login;
