import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { useProvider } from '../context';
import { routeNames } from '../routes';

const Logout = () => {
  const { dispatch, actions } = useProvider();

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        dispatch({ type: actions.SIGN_OUT });
      })
      .catch((error) => {
        console.log('signOut error', error);
      });
  }, []);

  return <Redirect to={routeNames.login} />;
};

export default Logout;
