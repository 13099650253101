import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../helpers';
import { routeNames } from '../../routes';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';

const Account = () => {
  const axios = useAxios();
  const [state, setState] = useState({});

  useEffect(() => {
    axios
      .get('/account')
      .then(({ data }) => {
        setState(data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  return (
    <div className="account">
      <p>
        <Link to={routeNames.account}>Account</Link>
        <Link to={routeNames.security}>Security</Link>
        <Link to={routeNames.store}>Store</Link>
      </p>
      <p>[account]</p>
      <ChangeEmailForm />
      <hr />
      <ChangePasswordForm />
    </div>
  );
};

export default Account;
