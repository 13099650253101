import React from 'react';
import RequestCodeForm from './RequestCodeForm';

const Forgot = () => {
  return (
    <div className="forgot">
      <p>[forgot]</p>
      <RequestCodeForm />
    </div>
  );
};

export default Forgot;
