import React from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from '../../routes';
import RegisterForm from './RegisterForm';

const Register = () => {
  return (
    <div className="register">
      <p>[register]</p>
      <RegisterForm />
      <Link to={routeNames.login}>Login</Link>
    </div>
  );
};

export default Register;
