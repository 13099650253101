import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../../../routes';

const Menu = () => {
  const location = useLocation();

  const links = [
    {
      name: 'Home',
      to: routeNames.home,
    },
    {
      name: 'Why Pay Zone',
      to: routeNames.why,
    },
  ];

  return (
    <div className="menu">
      {links.map(({ name, to }, i) => {
        return (
          <Link key={i} to={to} className={location.pathname === to ? 'active' : ''}>
            {name}
          </Link>
        );
      })}
    </div>
  );
};

export default Menu;
