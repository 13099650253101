import React, { useEffect, memo } from 'react';

const ScrollTop = ({ children }) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return <>{children}</>;
};

const pageWrapper = (Component, title) => () => {
  const Memoized = memo(() => (
    <ScrollTop>
      <Component />
    </ScrollTop>
  ));

  useEffect(() => {
    document.title = title;
  }, []);

  return <Memoized />;
};

export default pageWrapper;
