import React, { useReducer } from 'react';

const initialState = {
  isAuthenticated: false,
  email: '',
  token: '',
  created: 0,
};

const actions = {
  SIGN_OUT: 'SIGN_OUT',
  SIGN_IN: 'SIGN_IN',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SIGN_OUT: {
      return initialState;
    }
    case actions.SIGN_IN: {
      const { email, token, created } = action;

      return {
        ...state,
        email,
        token,
        created,
        isAuthenticated: true,
      };
    }
    default: {
      throw new Error(`unhandled type ${action.type}`);
    }
  }
};

const Context = React.createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

const useProvider = () => {
  const { state, dispatch } = React.useContext(Context);

  return { ...state, dispatch, actions };
};

export { Provider, useProvider };
