import React from 'react';
import { Link } from 'react-router-dom';
import { State, emojiNames } from '../components';

const NotFound = () => {
  return (
    <State
      emoji={emojiNames.crossmark}
      title="Not Found"
      description={window.location.href}
      action={<Link to={'/'}>Back home</Link>}
    />
  );
};

export default NotFound;
