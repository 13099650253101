import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { State, emojiNames } from '../../components';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
});

const ChangeEmailForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(false);

  return success ? (
    <State
      emoji={emojiNames.checkmark}
      title="Success"
      description={`Email successfully changed. Next time use the email ${email} to log in`}
    />
  ) : (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      validate={() => {
        setError(false);
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        try {
          let user = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(user, {
            email: values.email,
          });
          setEmail(values.email);
          setSuccess(true);
        } catch (error) {
          console.error('change email error', error);
          setError(error && error.message ? error.message : 'Something went wrong.');
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="text-left" noValidate>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={`form-control ${
                  'email' in errors && 'email' in touched ? 'is-invalid' : ''
                }`}
              />
              {/* TODO: use Formik erros? */}
              {'email' in errors && 'email' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.email}</small>
                </p>
              )}
            </div>
            <div>
              {error && (
                <p>
                  <small className="text-danger">{error}</small>
                </p>
              )}
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Changing email…' : 'Change email'}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangeEmailForm;
