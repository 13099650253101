import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { State, emojiNames, ResendButton } from '../../components';

const confirmationValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
});

const RequestCodeForm = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const action = useCallback(() => {
    Auth.forgotPassword(email);
  });

  const setErrorCallback = useCallback(() => {
    setError(error && error.message ? error.message : 'Something went wrong.');
  });

  const resendButtonProps = {
    action,
    setError: setErrorCallback,
    message: 'Confirmation email not received?',
  };

  return success ? (
    <div>
      <State
        emoji={emojiNames.mailbox}
        title="Check your email"
        description={`Click the confirmation link sent to ${email}`}
      />
      <ResendButton {...resendButtonProps} />
    </div>
  ) : (
    <div className="forgotForm">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={confirmationValidationSchema}
        validate={() => {
          setError(false);
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setEmail(values.email);
            await Auth.forgotPassword(values.email);
            setSuccess(true);
          } catch (error) {
            console.error('confirmation error', error);
            setError(error && error.message ? error.message : 'Something went wrong.');
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={`form-control ${
                    'email' in errors && 'email' in touched ? 'is-invalid' : ''
                  }`}
                />
                {/* TODO: use Formik erros? */}
                {'email' in errors && 'email' in touched && (
                  <p className="form-err">
                    <small className="text-danger">{errors.email}</small>
                  </p>
                )}
              </div>
              <div>
                {error && (
                  <p>
                    <small className="text-danger">{error}</small>
                  </p>
                )}
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Sending…' : 'Send confirmation'}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RequestCodeForm;
