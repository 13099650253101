import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Symbol } from '../../../assets/images/symbol.svg';
import { routeNames, layoutNames } from '../../../routes';

const Logo = ({ layout }) => {
  return (
    <Link to={layout === layoutNames.app ? routeNames.dashboard : routeNames.home} className="logo">
      <Symbol />
      {layout === layoutNames.app ? <>Dashboard</> : <>Pay Zone</>}
    </Link>
  );
};

export default Logo;
