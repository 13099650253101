import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { State, emojiNames } from '../../components';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().min(8, 'Minimum 8 characters').required('Password required'),
  newPassword: Yup.string().min(8, 'Minimum 8 characters').required('Password required'),
});

const ChangePasswordForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return success ? (
    <State
      emoji={emojiNames.checkmark}
      title="Success"
      description={`Password successfully changed. Next time you log in, use the new password.`}
    />
  ) : (
    <Formik
      initialValues={{ oldPassword: '', newPassword: '' }}
      validationSchema={validationSchema}
      validate={() => {
        setError(false);
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        try {
          let user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, values.oldPassword, values.newPassword);
          setSuccess(true);
        } catch (error) {
          console.error('change password error', error);
          setError(error && error.message ? error.message : 'Something went wrong.');
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="text-left" noValidate>
            <div>
              <label htmlFor="oldPassword">Old password</label>
              <input
                type="password"
                name="oldPassword"
                id="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
                className={`form-control ${
                  'oldPassword' in errors && 'oldPassword' in touched ? 'is-invalid' : ''
                }`}
              />
              {/* TODO: use Formik erros? */}
              {'oldPassword' in errors && 'oldPassword' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.oldPassword}</small>
                </p>
              )}
            </div>
            <div>
              <label htmlFor="newPassword">New password</label>
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                className={`form-control ${
                  'newPassword' in errors && 'newPassword' in touched ? 'is-invalid' : ''
                }`}
              />
              {/* TODO: use Formik erros? */}
              {'newPassword' in errors && 'newPassword' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.newPassword}</small>
                </p>
              )}
            </div>
            <div>
              {error && (
                <p>
                  <small className="text-danger">{error}</small>
                </p>
              )}
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Changing password…' : 'Change password'}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
