import React, { useState, useEffect, useCallback } from 'react';
import Auth from '@aws-amplify/auth';
import * as Yup from 'yup';
import { State, Loading, emojiNames, ResendButton } from '../../components';
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../../routes';

const schema = Yup.object().shape({
  action: Yup.string().required().oneOf(['sign-up', 'resend-code']),
  email: Yup.string().required().email(),
  code: Yup.string().required().matches(/[0-9]/g).length(6),
});

const Confirm = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  const params = {
    action: searchParams.get('action'),
    email: searchParams.get('email'),
    code: searchParams.get('code'),
  };
  const valid = schema.isValidSync(params);

  const action = useCallback(() => {
    Auth.resendSignUp(params.email);
  });

  const setErrorCallback = useCallback(() => {
    setError(error && error.message ? error.message : 'Something went wrong.');
  });

  const resendButtonProps = {
    action,
    setError: setErrorCallback,
    message: 'Confirmation email expired?',
    instantStart: true,
  };

  useEffect(() => {
    if (valid) {
      (async () => {
        // switch (params.action) {
        //   case 'sign-up': {
        try {
          console.log(params.email);
          await Auth.confirmSignUp(params.email, params.code);
          setSuccess(true);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
        //   }
        //   default:
        // }
      })();
    } else {
      console.log('this is false');
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && valid && success && (
        <State
          emoji={emojiNames.sunflower}
          title="Confirmation successful"
          description="You're all set. Proceed to sign in into your account."
          action={
            <Link className="" to={routeNames.login}>
              Sign In
            </Link>
          }
        />
      )}
      {!loading && (!valid || !success) && (
        <div>
          <State
            emoji={emojiNames.prohibited}
            title="Confirmation failed"
            description={error || 'Something went wrong with the confirmation.'}
            action={
              <Link className="" to={routeNames.home}>
                Back home
              </Link>
            }
          />
          <ResendButton {...resendButtonProps} />
        </div>
      )}
    </>
  );
};

export default Confirm;
