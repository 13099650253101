import React from 'react';
import { useProvider } from '../../../context';
import { layoutNames } from '../../../routes';
import Account from './Account';
import Auth from './Auth';
import Menu from './Menu';
import Logo from './Logo';

const Header = ({ layout }) => {
  const { isAuthenticated } = useProvider();

  return (
    <header className="header">
      <Logo layout={layout} />

      {(layout === layoutNames.home || layout === layoutNames.site) && <Menu />}

      {isAuthenticated ? <Account /> : <Auth />}
    </header>
  );
};

export default Header;
