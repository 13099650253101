import React, { useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { useProvider } from '../../context';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string().required('Password required'),
});

const LoginForm = () => {
  const [error, setError] = useState(false);
  const { dispatch, actions } = useProvider();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      validate={() => {
        setError(false);
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await Auth.signIn(values.email, values.password);
          const session = await Auth.currentSession();
          const token = session.getIdToken().getJwtToken();

          const {
            data: { created },
          } = await axios.get('/account', {
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 5000,
            headers: { Authorization: `Bearer ${token}` },
          });

          dispatch({
            type: actions.SIGN_IN,
            email: session.getIdToken().payload.email,
            token,
            created,
          });
        } catch (error) {
          console.error('signIn error', error);
          setError(error && error.message ? error.message : 'Something went wrong.');
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={`form-control ${
                  'email' in errors && 'email' in touched ? 'is-invalid' : ''
                }`}
              />
              {/* TODO: use Formik erros? */}
              {'email' in errors && 'email' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.email}</small>
                </p>
              )}
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={`form-control ${
                  'form-control password' in errors && 'password' in touched ? 'is-invalid' : ''
                }`}
              />

              {/* TODO: use Formik erros? */}
              {'password' in errors && 'password' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.password}</small>
                </p>
              )}
            </div>
            <div>
              {error && (
                <p>
                  <small className="text-danger">{error}</small>
                </p>
              )}
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Logging in…' : 'Login'}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
