import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { State, emojiNames, ResendButton } from '../../components';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string().min(8, 'Minimum 8 characters').required('Password required'),
  recaptcha: Yup.string().required('Captcha is required'),
});

const RegisterForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  const recaptchaRef = React.createRef();

  const action = useCallback(() => {
    Auth.resendSignUp(email);
  });

  const setErrorCallback = useCallback(() => {
    setError(error && error.message ? error.message : 'Something went wrong.');
  });

  const resendButtonProps = {
    action,
    setError: setErrorCallback,
    message: 'Confirmation email not received?',
  };

  return (
    <>
      {success ? (
        <div>
          <State
            emoji={emojiNames.outbox}
            title="Check your email"
            description={`Click the confirmation link sent to ${email}`}
          />
          <ResendButton {...resendButtonProps} />
        </div>
      ) : (
        <Formik
          initialValues={{ email: '', password: '', recaptcha: '' }}
          validationSchema={validationSchema}
          validate={() => {
            setError(false);
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError(false);
            try {
              console.log(values);
              setEmail(values.email);
              await Auth.signUp({
                username: values.email,
                password: values.password,
                clientMetadata: {
                  recaptcha: values.recaptcha,
                },
              });
              setSuccess(true);
            } catch (error) {
              console.error('signUp error', error);
              setError(
                error && error.message
                  ? { message: error.message, code: error.code }
                  : { message: 'Something went wrong.' }
              );
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit} className="text-left" noValidate>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={`form-control ${
                      'email' in errors && 'email' in touched ? 'is-invalid' : ''
                    }`}
                  />
                  {/* TODO: use Formik erros? */}
                  {'email' in errors && 'email' in touched && (
                    <p className="form-err">
                      <small className="text-danger">{errors.email}</small>
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={`form-control ${
                      'password' in errors && 'password' in touched ? 'is-invalid' : ''
                    }`}
                  />
                  {/* TODO: use Formik erros? */}
                  {'password' in errors && 'password' in touched && (
                    <p className="form-err">
                      <small className="text-danger">{errors.password}</small>
                    </p>
                  )}
                </div>
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    name="recaptcha"
                    id="recaptcha"
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('recaptcha', value);
                      }
                    }}
                    onExpired={() => {
                      setFieldValue('recaptcha', '');
                    }}
                    theme="light"
                  />
                  {/* TODO: use Formik erros? */}
                  {'recaptcha' in errors && 'recaptcha' in touched && (
                    <p className="form-err mt-1">
                      <small className="text-danger">{errors.recaptcha}</small>
                    </p>
                  )}
                </div>
                <div>
                  {error && (
                    <div>
                      <p>
                        <small className="text-danger">{error.message}</small>
                      </p>
                      {error.code && error.code === 'UsernameExistsException' && (
                        <ResendButton {...resendButtonProps} instantStart={true} />
                      )}
                    </div>
                  )}
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Creating account…' : 'Create account'}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default RegisterForm;
