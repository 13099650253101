import React from 'react';
import { Emoji } from '../Emoji';

const State = ({ emoji, title, description, action }) => (
  <div className="state">
    {emoji && <Emoji emoji={emoji} />}
    {title && <div>{title}</div>}
    {description && <div>{description}</div>}
    {action && <>{action}</>}
  </div>
);

export default State;
