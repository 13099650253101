import React from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from '../../../routes';

const Auth = () => {
  const links = [
    {
      name: 'Login',
      to: routeNames.login,
    },
    {
      name: 'Get started',
      to: routeNames.register,
    },
  ];

  return (
    <div className="auth">
      {links.map(({ name, to }, i) => {
        return (
          <Link key={i} to={to}>
            {name}
          </Link>
        );
      })}
    </div>
  );
};

export default Auth;
