import React from 'react';
import { appName } from '../../../config';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>
        &copy; {year} {appName}
      </p>
    </footer>
  );
};

export default Footer;
