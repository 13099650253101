import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { layoutNames, typeNames, routes } from './routes';
import Layout from './components/Layout';
import { Provider } from './context';
import AppAuth from './AppAuth';
import NotFound from './pages/NotFound';

const Routes = () => {
  const structure = {};

  routes.forEach(({ path, component, type, exact, layout }) => {
    if (!structure[layout]) {
      structure[layout] = [];
    }

    structure[layout].push({ path, component, type, exact });
  });

  return (
    <Switch>
      {Object.keys(structure).map((layout) => {
        return (
          <Route path={structure[layout].map(({ path }) => path)} key={layout} exact>
            <Layout layout={layout}>
              <Switch>
                {routes.map(({ path, component, type, exact }) => {
                  switch (type) {
                    case typeNames.authenticatedRoute:
                      return (
                        <AppAuth.AuthenticatedRoute path={path} component={component} key={path} />
                      );
                    case typeNames.unauthenticatedRoute:
                      return (
                        <AppAuth.UnauthenticatedRoute
                          path={path}
                          component={component}
                          key={path}
                        />
                      );
                    case typeNames.route:
                      return <Route path={path} component={component} exact={exact} key={path} />;
                    default:
                      return null;
                  }
                })}
              </Switch>
            </Layout>
          </Route>
        );
      })}
      <Route>
        <Layout layout={layoutNames.site}>
          <NotFound />
        </Layout>
      </Route>
    </Switch>
  );
};

const App = () => {
  return (
    <Provider>
      <AppAuth.AuthProvider>
        <Router>
          <Routes />
        </Router>
      </AppAuth.AuthProvider>
    </Provider>
  );
};

export default App;
