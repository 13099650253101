import React, { useState, useEffect } from 'react';

const secondsToResend = 5;

const ResendButton = (props) => {
  const initialWait = !props.instantStart ? secondsToResend : 0;
  const [isResendButtonDisabled, setResendDisabled] = useState(!props.instantStart);
  const [countDown, setCountdown] = useState(initialWait);
  const resendConfirmationCode = () => {
    setResendDisabled(true);
    setCountdown(secondsToResend);
    try {
      props.action();
    } catch (error) {
      console.error('resend error', error);
      props.setError();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!countDown) {
        setResendDisabled(false);
        return;
      }
      setCountdown((c) => c - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countDown]);

  return (
    <div className="resetButton">
      {props.message}{' '}
      <button onClick={resendConfirmationCode} disabled={isResendButtonDisabled}>
        Click here {isResendButtonDisabled ? `in ${countDown}` : ''}
      </button>{' '}
      to resend it
    </div>
  );
};

export default ResendButton;
