import React from 'react';

const Why = () => {
  return (
    <div>
      <p>[why]</p>
    </div>
  );
};

export default Why;
