import * as React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Route, Redirect } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { useProvider } from './context';
import { routeNames } from './routes';

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    storage: window.localStorage,
  },
});

const AuthProvider = ({ children }) => {
  const { dispatch, actions, token } = useProvider();
  const [ready, setReady] = React.useState(false);
  const timeoutRef = React.useRef();

  const fetchSession = () => {
    const getSession = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const {
          data: { created },
        } = await axios.get('/account', {
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 5000,
          headers: { Authorization: `Bearer ${token}` },
        });

        dispatch({
          type: actions.SIGN_IN,
          email: session.getIdToken().payload.email,
          token,
          created,
        });
        setReady(true);
      } catch (error) {
        dispatch({
          type: actions.SIGN_OUT,
        });
        setReady(true);
        console.error('currentSession error', error);
      }
    };

    getSession();
  };

  React.useEffect(fetchSession, []);

  React.useEffect(() => {
    if (token) {
      const { exp } = parseJwt(token);
      const refetchDate = moment.unix(exp).subtract(1, 'minutes');
      const difference = refetchDate.diff(moment());
      const timeout = moment.duration(difference).asMilliseconds();
      timeoutRef.current = setTimeout(() => {
        fetchSession();
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [token]);

  return ready ? <>{children}</> : null;
};

const AuthenticatedRoute = ({ component, ...rest }) => {
  const { isAuthenticated } = useProvider();

  if (!isAuthenticated) {
    return <Redirect to={routeNames.login} />;
  }

  return <Route {...rest} component={component} />;
};

const UnauthenticatedRoute = ({ component, ...rest }) => {
  const { isAuthenticated } = useProvider();

  if (isAuthenticated) {
    return <Redirect to={routeNames.dashboard} />;
  }

  return <Route {...rest} component={component} />;
};

export default class AppAuth extends React.Component {
  static AuthenticatedRoute = AuthenticatedRoute;
  static UnauthenticatedRoute = UnauthenticatedRoute;
  static AuthProvider = AuthProvider;

  render() {
    return null;
  }
}
