import { pageWrapper } from './helpers';
import { appName } from './config';

import {
  Home,
  Account,
  Confirm,
  Dashboard,
  Forgot,
  Login,
  Pay,
  Register,
  Security,
  Store,
  Why,
  Logout,
  Reset,
} from './pages';

const layoutNames = {
  home: 'home',
  site: 'site',
  auth: 'auth',
  app: 'app',
};

const typeNames = {
  route: 'route',
  authenticatedRoute: 'authenticatedRoute',
  unauthenticatedRoute: 'unauthenticatedRoute',
};

const routeNames = {
  home: '/',
  account: '/account',
  confirm: '/confirm',
  dashboard: '/dashboard',
  forgot: '/forgot',
  login: '/login',
  pay: '/:hash([a-z0-9]{64})',
  register: '/register',
  security: '/security',
  store: '/store',
  why: '/why',
  logout: '/logout',
  reset: '/reset',
};

const routes = [
  {
    path: routeNames.home,
    component: Home,
    type: typeNames.route,
    exact: true,
    layout: layoutNames.home,
  },
  {
    path: routeNames.account,
    title: 'Account',
    component: Account,
    type: typeNames.authenticatedRoute,
    layout: layoutNames.app,
  },
  {
    path: routeNames.confirm,
    title: 'Confirm',
    component: Confirm,
    type: typeNames.route,
    layout: layoutNames.app,
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Dashboard,
    type: typeNames.authenticatedRoute,
    layout: layoutNames.app,
  },
  {
    path: routeNames.forgot,
    title: 'Forgot',
    component: Forgot,
    type: typeNames.unauthenticatedRoute,
    layout: layoutNames.auth,
  },
  {
    path: routeNames.login,
    title: 'Login',
    component: Login,
    type: typeNames.unauthenticatedRoute,
    layout: layoutNames.auth,
  },
  {
    path: routeNames.pay,
    title: 'Pay',
    component: Pay,
    type: typeNames.route,
    layout: layoutNames.site,
  },
  {
    path: routeNames.register,
    title: 'Register',
    component: Register,
    type: typeNames.unauthenticatedRoute,
    layout: layoutNames.auth,
  },
  {
    path: routeNames.security,
    title: 'Security',
    component: Security,
    type: typeNames.authenticatedRoute,
    layout: layoutNames.app,
  },
  {
    path: routeNames.store,
    title: 'Store',
    component: Store,
    type: typeNames.authenticatedRoute,
    layout: layoutNames.app,
  },
  {
    path: routeNames.why,
    title: 'Why',
    component: Why,
    type: typeNames.route,
    layout: layoutNames.site,
  },
  {
    path: routeNames.logout,
    component: Logout,
    type: typeNames.route,
    layout: layoutNames.auth,
  },
  {
    path: routeNames.reset,
    component: Reset,
    type: typeNames.unauthenticatedRoute,
    layout: layoutNames.auth,
  },
].map((route) => {
  const title = route.title ? `${route.title} • ${appName}` : appName;

  return {
    ...route,
    component: pageWrapper(route.component, title),
  };
});

export { layoutNames, typeNames, routeNames, routes };
