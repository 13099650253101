import React, { useState } from 'react';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { State, emojiNames } from '../../components';
import { routeNames } from '../../routes';

const formSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Minimum 8 characters').required('Password required'),
});

const paramsSchema = Yup.object().shape({
  code: Yup.string().required().matches(/[0-9]/g).length(6),
  email: Yup.string().email('Invalid email').required('Email required'),
  action: Yup.string().required().oneOf(['forgot-password', 'resend-code']),
});

const Reset = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  const params = {
    email: searchParams.get('email'),
    code: searchParams.get('code'),
    action: searchParams.get('action'),
  };

  const paramsValid = paramsSchema.isValidSync(params);

  return !paramsValid ? (
    <State
      emoji={emojiNames.prohibited}
      title="Bad link"
      description={error || 'Something went wrong with the confirmation.'}
      action={
        <Link className="" to={routeNames.forgot}>
          Forgot password
        </Link>
      }
    />
  ) : success ? (
    <State
      emoji={emojiNames.sunflower}
      title="Password successfully changed"
      description="You're all set. Proceed to sign in into your account using your new password."
      action={
        <Link className="" to={routeNames.login}>
          Sign In
        </Link>
      }
    />
  ) : (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={formSchema}
      validate={() => {
        setError(false);
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await Auth.forgotPasswordSubmit(params.email, params.code, values.password);
          setSuccess(true);
        } catch (error) {
          console.error('changePasswordConfirm error', error);
          setError(error && error.message ? error.message : 'Something went wrong.');
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={`form-control ${
                  'password' in errors && 'password' in touched ? 'is-invalid' : ''
                }`}
              />
              {/* TODO: use Formik erros? */}
              {'password' in errors && 'password' in touched && (
                <p className="form-err">
                  <small className="text-danger">{errors.password}</small>
                </p>
              )}
            </div>
            <div>
              {error && (
                <p>
                  <small className="text-danger">{error}</small>
                </p>
              )}
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Resetting…' : 'Reset password'}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Reset;
