import React from 'react';

const emojiNames = {
  mailbox: '📫',
  popper: '🎉',
  outbox: '📤',
  crossmark: '❌',
  checkmark: '✅',
  hourglass: '⏳',
  paperclip: '📎',
  sunflower: '🌻',
  prohibited: '🚫',
};

const Emoji = ({ emoji }) => (
  <span className="emoji" role="img" aria-label="{symbol}">
    {emoji}
  </span>
);

export { Emoji, emojiNames };
